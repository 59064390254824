import * as React from 'react';

import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuWithItems,
} from '@av/ui/navigation-menu';
import { useProductSearch } from '../context/product-search-context';
import { BadgeHelp, BookOpen, Mail } from 'lucide-react';

export const CategoriesNavigationMenu = () => {
  const { categoryItems: items } = useProductSearch();

  const categoryCtx = [
    { pathname: '#', name: 'FAQ', icon: <BadgeHelp className="size-4" /> },
    { pathname: '#', name: 'Hoe het werkt', icon: <BookOpen className="size-4" /> },
    { pathname: '#', name: 'Neem contact op', icon: <Mail className="size-4" /> },
  ];

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuWithItems
          className="rounded-full bg-secondary text-description hover:bg-secondary/90"
          name="Assortiment"
          pathname="/assortiment"
          items={items}
          contextItems={categoryCtx}
        />
      </NavigationMenuList>
    </NavigationMenu>
  );
};
