'use client';

import { useTaxState } from '../context/tax-state-context';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@av/ui/form';

const TaxStateDropdown = () => {
  const { isTaxIncluded, setIsTaxIncluded } = useTaxState();

  const handleTaxStateChange = (value: string) => {
    const isTaxIncluded = value === 'incl';

    setIsTaxIncluded(isTaxIncluded);
    sessionStorage.setItem('taxState', JSON.stringify(isTaxIncluded));
  };

  /* eslint-disable tailwindcss/classnames-order */
  return (
    <Select
      value={isTaxIncluded ? 'incl' : 'excl'}
      onValueChange={(value) => handleTaxStateChange(value)}
    >
      <SelectTrigger
        aria-label="Tax toggle"
        className="whitespace-nowrap h-8 w-[7.75rem] min-w-[7.75rem] space-x-2 -mr-2 px-4 pr-10 text-base sm:text-sm sm:font-normal text-black sm:text-gray-600 hover:bg-neutral-100 transition-colors focus-visible:outline-none"
      >
        <SelectValue placeholder="Selecteer target" />
      </SelectTrigger>
      <SelectContent align="end">
        <SelectItem value="incl" className="text-base sm:text-sm py-4 sm:py-2">
          Incl. BTW
        </SelectItem>
        <SelectItem value="excl" className="text-base sm:text-sm py-4 sm:py-2">
          Excl. BTW
        </SelectItem>
      </SelectContent>
    </Select>
  );
};

export default TaxStateDropdown;
