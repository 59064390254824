'use client';

import { Button } from '@av/ui/button';
import { cn } from '@av/utils';
import Link from 'next/link';
import React from 'react';
import { NavbarLinkProps, NavbarLinkVariants } from './navbar';

export const NavbarClientLink = React.forwardRef<HTMLAnchorElement, NavbarLinkProps>(
  ({ className, variant = 'default' as const, href, children, ...props }, ref) => {
    return (
      <Button
        variant="ghost"
        size="sm"
        asChild
        className={cn(
          NavbarLinkVariants({
            variant,
          }),
          className
        )}
      >
        <Link ref={ref} href={href} {...props}>
          {children}
        </Link>
      </Button>
    );
  }
);
NavbarClientLink.displayName = 'NavbarClientLink';
