import Image from 'next/image';

export const ChristmasTheme = () => {
  return (
    <div className="pointer-events-none absolute inset-0 before:absolute before:inset-x-0 before:bottom-0 before:h-[1.2rem] before:bg-[url('/images/christmas-trees.png')] before:bg-top before:bg-repeat before:opacity-20 before:content-[''] after:pointer-events-none after:absolute after:inset-x-0 after:top-0 after:z-[-1] after:h-10 after:bg-[url('/images/icons/christmas-stars.svg')] after:bg-top after:bg-repeat after:content-['']">
      <Image
        src="/images/santa.png"
        alt="Christmas santa"
        width={166}
        height={100}
        className="absolute bottom-0 left-0 max-md:w-24"
      />
      <Image
        src="/images/present.png"
        alt="Christmas present"
        width={149}
        height={114}
        className="absolute bottom-0 right-0 max-md:w-24"
      />
    </div>
  );
};
