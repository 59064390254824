import { Button } from '@av/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@av/ui/dropdown';
import { cn } from '@av/utils';
import { VariantProps, cva } from 'class-variance-authority';

import Link, { LinkProps } from 'next/link';
import React from 'react';
import { sectionVariants } from '../section/section';
import { ChevronDown } from 'lucide-react';

const Navbar = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'nav'>>(
  ({ children, className, 'aria-label': ariaLabel, ...props }, ref) => {
    return (
      <aside
        className={cn(
          sectionVariants({ padding: 'x', gap: 'none', spacing: 'none' }),
          'w-full border-b border-neutral-300',
          className
        )}
      >
        <nav
          ref={ref}
          className="mx-auto flex h-full max-w-container items-center justify-between space-x-2 max-sm:w-[calc(100%+0.5rem)] sm:px-4 md:space-x-3"
          aria-label={ariaLabel}
          {...props}
        >
          {children}
        </nav>
      </aside>
    );
  }
);
Navbar.displayName = 'Navbar';

const NavbarItems = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('hidden sm:flex sm:items-center sm:space-x-2 lg::space-x-6', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);
NavbarItems.displayName = 'NavbarItems';

export const NavbarLinkVariants = cva('text-base font-medium leading-6 ', {
  variants: {
    variant: {
      default: '',
      active: 'text-primary hover:bg-primary/10 hover:text-primary/80',
      disabled: 'text-description',
    },
  },
});
export type NavbarLinkProps = LinkProps & {
  className?: string;
  children: React.ReactNode;
  variant?: VariantProps<typeof NavbarLinkVariants>['variant'];
};

const NavbarLink = React.forwardRef<HTMLAnchorElement, NavbarLinkProps>(
  ({ className, variant = 'default' as const, href, children, ...props }, ref) => {
    return (
      <Button
        variant="ghost"
        asChild
        className={cn(
          NavbarLinkVariants({
            variant: variant,
          }),
          className
        )}
      >
        <Link ref={ref} href={href} {...props}>
          {children}
        </Link>
      </Button>
    );
  }
);
NavbarLink.displayName = 'NavbarLink';

const NavbarDropdown: React.FC<React.ComponentPropsWithoutRef<typeof DropdownMenu>> = ({
  children,
  ...props
}) => {
  return <DropdownMenu {...props}>{children}</DropdownMenu>;
};
NavbarDropdown.displayName = 'NavbarDropdown';

const NavbarDropdownTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuTrigger>
>(({ children, className, ...props }, ref) => {
  return (
    <DropdownMenuTrigger
      ref={ref}
      className={cn('flex items-center font-medium text-sm text-description h-9 px-2', className)}
      asChild
      {...props}
    >
      <Button variant={'ghost'}>
        {children}
        <ChevronDown className="ml-0.5 size-4 stroke-description" />
      </Button>
    </DropdownMenuTrigger>
  );
});
NavbarDropdownTrigger.displayName = 'NavbarDropdownTrigger';

const NavbarDropdownContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuContent>
>(({ children, ...props }, ref) => {
  return (
    <DropdownMenuContent
      ref={ref}
      className="mt-2 w-64 rounded-lg bg-white px-0 py-1 shadow-lg"
      {...props}
    >
      {children}
    </DropdownMenuContent>
  );
});
NavbarDropdownContent.displayName = 'NavbarDropdownContent';

const NavbarDropdownLink = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'>>(
  ({ className, href = '#', children, ...props }) => {
    const isActive = false;

    return (
      <DropdownMenuItem key={href} className="p-0">
        <a
          href={href}
          className={cn(
            `${isActive && 'bg-neutral-100 '} block px-4 py-2 hover:bg-neutral-100 w-full`,
            className
          )}
          {...props}
        >
          {children}
        </a>
      </DropdownMenuItem>
    );
  }
);
NavbarDropdownLink.displayName = 'NavbarDropdownLink';
const NavbarDropdownMenuGroup = DropdownMenuGroup;
// NavbarDropdownMenuGroup.displayName = 'NavbarDropdownMenuGroup';

const NavbarActions = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('hidden lg:flex lg:flex-1 lg:justify-end lg:space-x-2', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);
NavbarActions.displayName = 'NavbarActions';

const NavbarBrand = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('flex ', className)} {...props}>
        {children}
      </div>
    );
  }
);
NavbarBrand.displayName = 'NavbarBrand';

const NavbarLogo = React.forwardRef<HTMLAnchorElement, NavbarLinkProps>(
  ({ className, href, children, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        href={href}
        className={cn('flex items-center sm:-m-1.5 sm:p-1.5', className)}
        {...props}
      >
        {children}
      </Link>
    );
  }
);
NavbarLogo.displayName = 'NavbarLogo';

export {
  Navbar,
  NavbarActions,
  NavbarBrand,
  NavbarDropdown,
  NavbarDropdownContent,
  NavbarDropdownLink,
  NavbarDropdownMenuGroup,
  NavbarDropdownTrigger,
  NavbarItems,
  NavbarLink,
  NavbarLogo,
};
