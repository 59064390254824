import * as React from 'react';

import { NavbarClientLink } from '@/components/navbar/navbar-client-link';
import { NavigationItem } from '@av/ui/navigation-menu';
import {
  NavbarDropdown,
  NavbarDropdownContent,
  NavbarDropdownLink,
  NavbarDropdownMenuGroup,
  NavbarDropdownTrigger,
  NavbarItems,
} from '@/components/navbar/navbar';

export const UpperNavigationMenu = ({ items }: { items: NavigationItem[] }) => (
  <NavbarItems>
    {items.map(({ pathname, name, links }, i) =>
      links?.length ? (
        <NavbarDropdown key={i}>
          <NavbarDropdownTrigger className="">{name}</NavbarDropdownTrigger>
          <NavbarDropdownContent className="z-[999]">
            <NavbarDropdownMenuGroup>
              {links.map((childItem, idx) => (
                <NavbarDropdownLink key={idx} href={childItem.pathname || '#'} className="py-3">
                  {childItem.name}
                </NavbarDropdownLink>
              ))}
            </NavbarDropdownMenuGroup>
          </NavbarDropdownContent>
        </NavbarDropdown>
      ) : (
        <NavbarClientLink key={i} href={pathname || '#'} className="-ml-3 text-sm text-description">
          {name}
        </NavbarClientLink>
      )
    )}
  </NavbarItems>
);
