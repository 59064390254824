'use client';

import React from 'react';

import Header, { HeaderType } from './header';
import IframeMessageProvider from '@/app/(static)/[[...slug]]/components/iframe-message-provider';

const Client = ({ header, client }: { header: HeaderType; client: boolean }) => {
  return (
    <IframeMessageProvider<HeaderType> defaultValue={header} eventType="SET_HEADER">
      {(data: HeaderType) => <Header header={data} client={client} />}
    </IframeMessageProvider>
  );
};

export default Client;
