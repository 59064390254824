import Link from 'next/link';
import React from 'react';

const Star = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1726_19018)">
      <path d="M8.00025 0.25L10.7196 4.88637L16.0003 6.02195L12.4003 10.0229L12.9445 15.3611L8.00025 13.1975L3.05597 15.3611L3.60025 10.0229L0.000244141 6.02195L5.2809 4.88637L8.00025 0.25Z" />
    </g>
    <defs>
      <clipPath id="clip0_1726_19018">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ReviewRating = ({ rating }: { rating: number }) => {
  return (
    <Link
      href="https://www.google.com/search?sca_esv=4e4a8a2bd4267cc2&q=accuraat+verhuur&uds=ADvngMhGB-VtBMom1-3tEuR0_dZAm-cZoaS-vCrUdzA4k9CVoEINDA9v6p158sbaWrrp9zUhVE0CNelDxs77UpKnUL8bfm-XEOaN2oTtPSeMuB31riyWZaz8XAToyjJ14UlGjTme_xmh&si=ACC90nzcy7sviKw0NTZoUBUzhQehr3jouizIrVSf6avWI23m1bBcoq-_DDpdloB0rgwQH-kg_n4d_VO8RIeeBbEx3VjlFA6h9z9mcSmrwAPeoXYmocfn8Sk%3D&sa=X&sqi=2&ved=2ahUKEwi5rPyZnPKIAxUF9rsIHXrPALoQ3PALegQIGRAE&biw=2560&bih=1294&dpr=1"
      target="_blank"
      className="flex items-center whitespace-nowrap text-sm text-description"
    >
      <div className="relative">
        <div className="mr-2 flex space-x-1">
          {Array(5)
            .fill('')
            .map((_, i) => {
              const currentRating = i + 1;
              const relativeRating = rating / 10;
              if (relativeRating > currentRating)
                return <Star className="fill-[#FFCB45]" key={i} />;
              if (relativeRating + 1 > currentRating)
                return (
                  <div className="relative" key={i}>
                    <Star className="fill-secondary" />
                    <div
                      className="absolute inset-0 overflow-hidden"
                      style={{ width: 16 * (relativeRating - i) }}
                    >
                      <Star className="fill-[#FFCB45]" />
                    </div>
                  </div>
                );

              return <Star className="fill-secondary" key={i} />;
            })}
        </div>
      </div>
      <span className="max-xl:hidden">Gem. beoordeeld met een</span>
      <b className="ml-1">{Math.min(5, Math.round(rating) / 10)} / 5</b>
    </Link>
  );
};

export default ReviewRating;
