'use client';

import { useEffect } from 'react';

const PreferenceCookies = ({ show }: { show: boolean }) => {
  useEffect(() => {
    const scriptId = 'ze-snippet';
    const scriptSrc =
      'https://static.zdassets.com/ekr/snippet.js?key=32fdf20b-0317-47a9-8f0b-40103ece9cfe';

    const addScript = () => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = scriptSrc;
        script.async = true;
        document.body.appendChild(script);
      }
    };

    const removeScript = () => {
      const script = document.getElementById(scriptId);
      if (script) {
        script.remove();
      }

      // Remove the iframes
      const webWidgetIframe = document.querySelector('iframe[data-product="web_widget"]');
      if (webWidgetIframe) {
        webWidgetIframe.remove();
      }

      const launcherIframe = document.getElementById('launcher');
      if (launcherIframe) {
        launcherIframe.remove();
      }

      // Remove any other Zendesk-related elements
      const zendeskElements = document.querySelectorAll(
        '[id^="zsalesbox"], [id^="ze-snippet"], [id^="zd-settings"]'
      );
      zendeskElements.forEach((element) => element.remove());
    };

    if (show) {
      addScript();
    } else {
      removeScript();
    }

    // Cleanup function to ensure script and iframe are removed when component unmounts
    return () => {
      removeScript();
    };
  }, [show]);

  return null;
};

export default PreferenceCookies;
