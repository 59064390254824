import { cn } from '@av/utils';
import React from 'react';

const Footer = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'footer'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <footer ref={ref} aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>

        <div className={cn('px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32', className)} {...props}>
          {children}
        </div>
      </footer>
    );
  }
);
Footer.displayName = 'Footer';

const FooterContent = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-14 py-20',
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);
FooterContent.displayName = 'FooterContent';

const FooterColumns = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex max-md:flex-col w-full sm:gap-10 xl:gap-20', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);
FooterColumns.displayName = 'FooterColumns';

const FooterColumn = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('mt-10 sm:mt-0', className)} {...props}>
        {children}
      </div>
    );
  }
);
FooterColumn.displayName = 'FooterColumn';

const FooterHeading = React.forwardRef<HTMLHeadingElement, React.ComponentPropsWithoutRef<'h3'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <h3
        ref={ref}
        className={cn('text-lg md:text-2xl font-semibold leading-6', className)}
        {...props}
      >
        {children}
      </h3>
    );
  }
);
FooterHeading.displayName = 'FooterHeading';

const FooterColumnList = React.forwardRef<HTMLUListElement, React.ComponentPropsWithoutRef<'ul'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <ul ref={ref} role="list" className={cn('mt-2 space-y-4 lg:mt-9', className)} {...props}>
        {children}
      </ul>
    );
  }
);
FooterColumnList.displayName = 'FooterColumnList';

const FooterColumnListItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<'li'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <li
        ref={ref}
        className={cn(
          'text-sm md:text-base font-normal leading-6 text-description hover:text-description-dark',
          className
        )}
        {...props}
      >
        {children}
      </li>
    );
  }
);
FooterColumnListItem.displayName = 'FooterColumnListItem';

const FooterSection = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex max-w-xs flex-col gap-4 sm:max-w-lg', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);
FooterSection.displayName = 'FooterSection';

const FooterBanner = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex flex-col justify-between space-y-6 border-t border-neutral-900/10 pt-10 md:flex-row md:items-end',
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);
FooterBanner.displayName = 'FooterBanner';

const FooterCopyright = React.forwardRef<HTMLParagraphElement, React.ComponentPropsWithoutRef<'p'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <p ref={ref} className={cn('text-xs leading-5 text-description ', className)} {...props}>
        {children}
      </p>
    );
  }
);
FooterCopyright.displayName = 'FooterCopyright';

const FooterLink = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <a
        ref={ref}
        className={cn(
          'text-xs leading-5 text-description underline hover:text-description-dark',
          className
        )}
        {...props}
      >
        {children}
      </a>
    );
  }
);
FooterLink.displayName = 'FooterLink';

export {
  Footer,
  FooterBanner,
  FooterColumn,
  FooterColumnList,
  FooterColumnListItem,
  FooterColumns,
  FooterContent,
  FooterCopyright,
  FooterHeading,
  FooterLink,
  FooterSection,
};
