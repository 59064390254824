import { Button } from '@av/ui/button';
import { UserCheck, UserIcon } from 'lucide-react';
import { useSession } from 'next-auth/react';
import React from 'react';

const UserNav = ({ cb }: { cb?: () => void }) => {
  const session = useSession();

  return (
    <Button
      variant="secondary"
      size="icon"
      aria-label="account"
      href={session?.data?.user?.isGuest ? '/login' : '/account/overzicht'}
      onClick={cb ? () => cb?.() : undefined}
    >
      {session?.data?.user && !session.data.user.isGuest ? (
        <UserCheck className="ml-1 size-5 text-primary" />
      ) : (
        <UserIcon className="size-5" />
      )}
    </Button>
  );
};

export default UserNav;
