'use client';

import type { ReactNode } from 'react';
import { signIn, useSession } from 'next-auth/react';
import { notifyError } from '@av/bugsnag';

export const AnonymousSessionProvider = ({ children }: { children: ReactNode }) => {
  useSession({
    required: true,
    onUnauthenticated() {
      setTimeout(async () => {
        try {
          // check if providers are available so errors can be handled gracefully
          await fetch('/api/auth/providers');
          await signIn('anonymous', { redirect: false });
        } catch (error) {
          notifyError(error);
        }
      }, 1000);
    },
  });

  return <>{children}</>;
};
