'use client';

import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '.';
import React from 'react';
import { cn } from '@av/utils';
import Link from 'next/link';

export type NavigationContextItem = { pathname: string; name: string; icon: React.ReactNode };
export type NavigationItem = React.ComponentPropsWithoutRef<'a'> & {
  name: string;
  pathname: string;
  items?: NavigationItem[];
  links?: NavigationItem[];
  active?: boolean;
  contextItems?: NavigationContextItem[];
};

const ListItem = React.forwardRef<HTMLAnchorElement, NavigationItem & { items?: NavigationItem[] }>(
  ({ className, title, active, href = '#', ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            className={cn(
              'block select-none py-3 px-7 leading-none no-underline outline-none transition-colors',
              active ? 'bg-secondary/90' : 'hover:bg-secondary/90 focus:bg-secondary/90',
              className
            )}
            href={href}
            ref={ref}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = 'ListItem';

const NavigationMenuWithItems = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> &
    NavigationItem & {
      items: NavigationItem[];
      contextItems?: NavigationContextItem[];
      /* eslint-disable no-unused-vars */
      cb?: (s: string[]) => void;
    }
>(({ name, items, contextItems, cb, ...props }, ref) => {
  const [focusItem, setFocusItem] = React.useState<NavigationItem | null>(null);

  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger {...props} ref={ref}>
        {name}
      </NavigationMenuTrigger>
      <NavigationMenuContent onMouseLeave={() => setFocusItem(null)}>
        <div className="flex">
          <div className="md:min-w-[17.5rem] max-h-[calc(100vh-15rem)] overflow-auto py-4 border-r border-border">
            <p className="font-bold text-base md:text-lg px-7 py-2">{name}</p>
            <ul>
              {items.map(({ items, id, name: displayName, pathname }, i) => (
                <ListItem
                  active={focusItem?.id === id}
                  href={pathname}
                  key={i}
                  name={name}
                  onClick={() => id && cb?.([id.toString()])}
                  onFocus={() => items && setFocusItem({ items, id, name: displayName, pathname })}
                  onMouseOver={() =>
                    items && setFocusItem({ items, id, name: displayName, pathname })
                  }
                  pathname={pathname}
                  title={displayName}
                >
                  {displayName}
                </ListItem>
              ))}
            </ul>
          </div>
          <div className="md:min-w-[17.5rem] max-h-[calc(100vh-15rem)] overflow-auto py-4">
            {focusItem ? (
              <>
                <p className="font-bold text-base md:text-lg px-7 py-2">{focusItem.name}</p>
                <ul>
                  {focusItem.items && focusItem.items.length > 0 ? (
                    focusItem.items.map(({ id, name: displayName, pathname }, i) => (
                      <ListItem
                        href={pathname}
                        key={i}
                        name={name}
                        onClick={() =>
                          focusItem.id && id && cb?.([focusItem.id.toString(), id.toString()])
                        }
                        pathname={pathname}
                        title={displayName}
                      >
                        {displayName}
                      </ListItem>
                    ))
                  ) : (
                    <div className="block select-none py-3 px-7 leading-none no-underline outline-none">
                      <p className="text-sm font-medium leading-none">Geen items aanwezig</p>
                    </div>
                  )}
                </ul>
              </>
            ) : null}
          </div>{' '}
        </div>
        {!!contextItems && (
          <nav className="px-7 py-5 bg-background">
            <p className="text-sm uppercase mb-5 text-gray-600">Service</p>
            <ul className="flex justify-between">
              {contextItems.map(({ pathname = '#', name, icon }, i) => (
                <li key={i}>
                  <Link
                    className="space-x-3 flex items-center text-sm font-normal hover:underline"
                    href={pathname}
                  >
                    <div className="size-6 rounded-full bg-primary/10 flex items-center justify-center text-primary">
                      {icon}
                    </div>
                    <span>{name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
});
NavigationMenuWithItems.displayName = 'NavigationMenuWithItems';

export { ListItem, NavigationMenuWithItems };
