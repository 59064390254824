import React from 'react';

import {
  NavbarMobile,
  NavbarMobileContent,
  NavbarMobileGroup,
  NavbarMobileItems,
  NavbarMobileLink,
  useNavbarMobile,
} from '@/components/navbar/navbar-mobile';
import { Button } from '@av/ui/button';
import { Phone } from 'lucide-react';
import ShoppingBag from './shopping-bag';
import TaxStateDropdown from './tax-state-dropdown';
import { NavigationItem } from '@av/ui/navigation-menu';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@av/ui/accordion';
import UserNav from './user-nav';

const MobileMenu = ({ items }: { items: NavigationItem[] }) => {
  const { setMobileMenuOpen } = useNavbarMobile();

  return (
    <NavbarMobile>
      <NavbarMobileContent>
        <NavbarMobileGroup className="flex h-full flex-col">
          <NavbarMobileItems className="flex h-full max-h-[calc(100vh-10rem)] flex-col overflow-y-auto overflow-x-hidden">
            <NavbarMobileLink href="/">Home</NavbarMobileLink>
            {items.map(({ pathname, name, links }, i) =>
              links?.length ? (
                <Accordion type="single" collapsible key={i}>
                  <AccordionItem value="item-1" className="border-none">
                    <AccordionTrigger className="py-2">{name}</AccordionTrigger>
                    <AccordionContent className="py-0 pl-3">
                      {links.map(({ pathname, name }, i) => (
                        <NavbarMobileLink href={pathname} key={i}>
                          {name}
                        </NavbarMobileLink>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ) : (
                <NavbarMobileLink href={pathname} key={i}>
                  {name}
                </NavbarMobileLink>
              )
            )}
          </NavbarMobileItems>
          <div className="flex h-20 flex-row items-center gap-2 space-y-0 border-t border-neutral-300">
            <UserNav cb={() => setMobileMenuOpen(false)} />
            <a href="tel:0206410920" className="flex items-center gap-2">
              <Button variant="secondary" size="icon">
                <Phone className="size-5" />
                <span className="sr-only">Bel ons</span>
              </Button>
            </a>
            <ShoppingBag />

            <TaxStateDropdown />
          </div>
        </NavbarMobileGroup>
      </NavbarMobileContent>
    </NavbarMobile>
  );
};

export default MobileMenu;
