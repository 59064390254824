'use client';

import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

function IframeMessageProvider<T>({
  children,
  defaultValue,
  eventType,
}: {
  eventType: string;
  // eslint-disable-next-line no-unused-vars
  children: (messageData: T) => ReactNode;
  defaultValue: T;
}) {
  const [messageData, setMessageData] = useState<T>(defaultValue);

  const getMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin === process.env.NEXT_PUBLIC_API_URL && event.data.type === eventType) {
        setMessageData(event.data.data as T);
      }
    },
    [eventType, setMessageData]
  );

  useEffect(() => {
    window.addEventListener('message', getMessage);

    return () => {
      window.removeEventListener('message', getMessage);
    };
  }, [getMessage]);

  return children(messageData);
}

export default IframeMessageProvider;
