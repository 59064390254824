'use client';

/* eslint-disable no-unused-vars */

import { NavbarLink, NavbarLinkProps, NavbarLinkVariants } from '@/components/navbar/navbar';
import { Button, buttonVariants } from '@av/ui/button';
import Hamburger from 'hamburger-react';

import { cn } from '@av/utils';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { XIcon } from 'lucide-react';
import Link from 'next/link';
import React from 'react';
import { create } from 'zustand';

const Dialog = DialogPrimitive.Root;
const DialogContent = DialogPrimitive.Content;
const DialogTrigger = DialogPrimitive.Trigger;

const useNavbarMobile = create<{
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
}>((set) => ({
  mobileMenuOpen: false,
  setMobileMenuOpen: (open: boolean) => set({ mobileMenuOpen: open }),
}));

const NavbarMobile: React.FC<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root>> = ({
  children,
  ...props
}) => {
  const { mobileMenuOpen, setMobileMenuOpen } = useNavbarMobile();

  return (
    <Dialog open={mobileMenuOpen} onOpenChange={setMobileMenuOpen} {...props}>
      <div className="flex items-center justify-center">
        <NavbarMobileTrigger
          className={buttonVariants({
            variant: 'secondary',
            size: 'icon',
            className: 'px-0 hover:bg-secondary',
          })}
        >
          <div>
            <Hamburger toggled={mobileMenuOpen} size={20} />
          </div>
        </NavbarMobileTrigger>
        <div className="sm:hidden">{children}</div>
      </div>
    </Dialog>
  );
};
NavbarMobile.displayName = 'NavbarMobile';

const NavbarMobileTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'>
>(({ className, children, 'aria-label': title = 'Open menu', ...props }, ref) => {
  return (
    <div className="flex sm:hidden">
      <DialogTrigger ref={ref} asChild {...props}>
        <Button type="button" variant="ghost" size="sm" className={className}>
          <span className="sr-only">{title}</span>
          {children}
        </Button>
      </DialogTrigger>
    </div>
  );
});
NavbarMobileTrigger.displayName = 'NavbarMobileTrigger';

const NavbarMobileClose = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'>
>(({ className, ...props }, ref) => {
  return (
    <DialogPrimitive.Close ref={ref} asChild>
      <Button type="button" variant="ghost" size="sm" className={className} {...props}>
        <XIcon className="size-6 stroke-neutral-800" aria-hidden="true" />
        <span className="sr-only">Close</span>
      </Button>
    </DialogPrimitive.Close>
  );
});
NavbarMobileClose.displayName = 'NavbarMobileClose';

const NavbarMobileHeader = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('flex items-center justify-between', className)} {...props}>
        {children}
      </div>
    );
  }
);
NavbarMobileHeader.displayName = 'NavbarMobileHeader';

const NavbarMobileActions = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('flex flex-col space-y-4 py-6', className)} {...props}>
        {children}
      </div>
    );
  }
);
NavbarMobileActions.displayName = 'NavbarMobileActions';

const NavbarMobileContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, className, ...props }, ref) => {
  return (
    <DialogContent
      ref={ref}
      className={cn(
        'fixed top-20 h-[calc(100%-5rem)] right-0 z-50 w-full overflow-y-auto bg-white px-6 sm:max-w-sm sm:ring-1 sm:ring-neutral-900/10 animate-in duration-500 slide-in-from-left',
        className
      )}
      {...props}
    >
      {children}
    </DialogContent>
  );
});
NavbarMobileContent.displayName = 'NavbarMobileContent';

const NavbarMobileGroup = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('divide-y divide-neutral-500/30', className)} {...props}>
        {children}
      </div>
    );
  }
);
NavbarMobileGroup.displayName = 'NavbarMobileGroup';

const NavbarMobileItems = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('space-y-2 py-6', className)} {...props}>
        {children}
      </div>
    );
  }
);
NavbarMobileItems.displayName = 'NavbarMobileItems';

const NavbarMobileLink = React.forwardRef<HTMLAnchorElement, NavbarLinkProps>(
  ({ className, variant = 'default' as const, href, children, ...props }, ref) => {
    const { setMobileMenuOpen } = useNavbarMobile();

    return (
      <Link
        ref={ref}
        href={href}
        onClick={() => setMobileMenuOpen(false)}
        className={cn(
          '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-neutral-100 ',
          NavbarLinkVariants({ variant }),
          className
        )}
        {...props}
      >
        {children}
      </Link>
    );
  }
);
NavbarMobileLink.displayName = 'NavbarMobileLink';

const NavbarMobileLogo = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'>>(
  ({ children, 'aria-label': title, href, className, ...props }, ref) => {
    return href ? (
      <NavbarLink ref={ref} href={href} className={cn('-m-1.5 p-1.5', className)} {...props}>
        <span className="sr-only">{title}</span>
        {children}
      </NavbarLink>
    ) : null;
  }
);
NavbarMobileLogo.displayName = 'NavbarMobileLogo';

const NavbarMobileDropdownLabel = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, children, ...props }, ref) => {
  return (
    <span
      ref={ref}
      className={cn(
        '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7',
        className
      )}
      {...props}
    >
      {children}
    </span>
  );
});
NavbarMobileDropdownLabel.displayName = 'NavbarMobileDropdownLabel';

const NavbarMobileDropdownGroup = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('ml-4 mt-1', className)} {...props}>
      {children}
    </div>
  );
});
NavbarMobileDropdownGroup.displayName = 'NavbarMobileDropdownGroup';

const NavbarMobileDropdownLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'>
>(({ className, href, children, ...props }, ref) => {
  return (
    <a
      ref={ref}
      href={href}
      className={cn(
        '-mx-3 block rounded-lg px-3 py-2 text-sm font-medium leading-7 text-description hover:bg-neutral-100',
        className
      )}
      {...props}
    >
      {children}
    </a>
  );
});
NavbarMobileDropdownLink.displayName = 'NavbarMobileDropdownLink';

export {
  NavbarMobile,
  NavbarMobileActions,
  NavbarMobileClose,
  NavbarMobileContent,
  NavbarMobileDropdownGroup,
  NavbarMobileDropdownLabel,
  NavbarMobileDropdownLink,
  NavbarMobileGroup,
  NavbarMobileHeader,
  NavbarMobileItems,
  NavbarMobileLink,
  NavbarMobileLogo,
  NavbarMobileTrigger,
  useNavbarMobile,
};
