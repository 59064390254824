'use client';

import React from 'react';

import Footer, { FooterType } from './footer';
import IframeMessageProvider from '@/app/(static)/[[...slug]]/components/iframe-message-provider';

const Client = ({ footer }: { footer: FooterType }) => {
  return (
    <IframeMessageProvider<FooterType> defaultValue={footer} eventType="SET_FOOTER">
      {(data: FooterType) => <Footer footer={data} />}
    </IframeMessageProvider>
  );
};

export default Client;
