import { Button } from '@av/ui/button';
import { Heart } from 'lucide-react';
import React from 'react';

const OrderListsMenuItem = () => {
  return (
    <div className="relative">
      <Button variant="secondary" size="icon" href="/bestellijsten" aria-label="Bestellijsten">
        <Heart className="size-5" />
        <span className="sr-only">Bestellijsten</span>
      </Button>
    </div>
  );
};

export default OrderListsMenuItem;
