'use client';

import { Button } from '@av/ui/button';
import { cn } from '@av/utils';
import { CookieIcon } from 'lucide-react';
import { useCookie } from './cookie-context';

/* eslint-disable no-unused-vars */
export default function CookieConsent({ consent, close }: { consent: boolean; close: () => void }) {
  const { updateCookies } = useCookie();

  const acceptAllCookies = () => {
    updateCookies({
      analytical: true,
      marketing: true,
      preferences: true,
    });
  };

  const acceptNecessaryCookies = () => {
    updateCookies({
      analytical: false,
      marketing: false,
      preferences: false,
    });
  };

  return (
    <div
      data-test-id="cookie-consent-banner"
      className={cn(
        'fixed bottom-0 left-0 right-0 z-[200] w-full transition-transform duration-700 sm:bottom-2.5 sm:left-5 sm:max-w-md',
        consent
          ? 'translate-y-8 opacity-0 transition-[opacity,transform] pointer-events-none'
          : 'translate-y-0 opacity-100 transition-[opacity,transform]'
      )}
    >
      <div className="m-2 rounded-md border bg-white">
        <div className="grid gap-2">
          <div className="flex h-14 items-center justify-between border-b p-4">
            <div className="text-lg font-medium">Wij gebruiken cookies</div>
            <CookieIcon className="size-[1.2rem] text-primary" />
          </div>
          <div className="p-4">
            <div className="text-sm font-normal">
              Bij Accuraat Verhuur maken we gebruik van cookies en vergelijkbare technologieën voor
              verschillende doeleinden. We plaatsen functionele cookies om onze website goed te
              laten werken, analytische cookies om onze dienstverlening te verbeteren, en
              marketingcookies om je gepersonaliseerde advertenties te tonen. Je hebt controle over
              je voorkeuren en kunt kiezen welke cookies je toestaat.
            </div>
          </div>
          <div className="flex flex-col gap-2 p-4 pt-0">
            <Button
              onClick={acceptNecessaryCookies}
              className="w-full text-sm font-normal"
              size="default"
              variant="secondary"
              data-test-id="accept-necessary-cookies"
            >
              Alleen noodzakelijke cookies
            </Button>
            <Button
              onClick={acceptAllCookies}
              className="w-full text-sm font-normal"
              size="default"
              data-test-id="accept-all-cookies"
            >
              Alle cookies accepteren
            </Button>
            <Button
              onClick={() => close()}
              href="/cookiebeleid"
              className="w-full text-sm font-normal"
              variant="outline"
              size="default"
              data-test-id="manage-cookie-settings"
            >
              Cookie-instellingen beheren
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
