'use client';

import RichContent from '@/app/(static)/[[...slug]]/blocks/rich-content';
import { Button } from '@av/ui/button';
import { X } from 'lucide-react';
import React, { useState, useEffect, useMemo } from 'react';

const Banner = ({ content, client }: { content: string; client: boolean }) => {
  const [isVisible, setIsVisible] = useState(false);

  const stripHtml = (html: string) => {
    return html.replace(/<[^>]*>?/gm, '').replace(/\s+/g, '');
  };

  const strippedContent = useMemo(() => stripHtml(content), [content]);

  useEffect(() => {
    const storedContent = localStorage.getItem('hiddenBannerContent');
    if (storedContent !== stripHtml(content) || client) {
      setIsVisible(true);
    }
  }, [content]);

  const handleClose = () => {
    localStorage.setItem('hiddenBannerContent', strippedContent);
    setIsVisible(false);
  };

  if (!isVisible || !strippedContent) return null;

  return (
    <div className="absolute relative inset-x-0 top-full flex items-center justify-between bg-primary px-3 py-2 text-white duration-500 animate-in fade-in-0 slide-in-from-top-1 md:px-5">
      <RichContent
        content={content}
        className="mx-auto max-w-container flex-1 text-center text-sm [&_a]:text-white"
      />
      <Button
        variant="ghost"
        size="icon"
        aria-label="Close"
        className="text-white hover:bg-white/10 md:absolute md:right-5 md:top-1/2 md:-translate-y-1/2"
        onClick={handleClose}
      >
        <X className="size-4 text-inherit" />
        <span className="sr-only">Sluiten</span>
      </Button>
    </div>
  );
};

export default Banner;
