'use client';

import React from 'react';
import PreferenceCookies from './preference-cookies';
import MarketingCookies from './marketing-cookies';
import { useCookie } from './cookie-context';
import GoogleAnalytics from './google-analytics';

const CookieScripts = () => {
  const { cookies } = useCookie();

  return (
    <>
      <GoogleAnalytics />
      <PreferenceCookies show={!!cookies?.preferences} />
      {cookies?.marketing && <MarketingCookies />}
    </>
  );
};

export default CookieScripts;
