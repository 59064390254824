export const GA_TRACKING_ID =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'GTM-TQ5K6L9'
    : process.env.NEXT_PUBLIC_GOOGLE_ID;

export const GA_TAG_ID =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'G-RNSWDQMNCC'
    : process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;

export const pageview = (url: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const event = ({ action, category, label, value }: any) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};
