import { Logo } from '@/assets/logo';

import {
  FooterColumn,
  FooterColumnList,
  FooterColumnListItem,
  FooterColumns,
  FooterCopyright,
  FooterHeading,
} from '@/components/footer/footer';

import { Section } from '@/components/section/section';
import { NavigationMenuForm } from '@av/validators';
import { CreditCard, Mail, PhoneCall, RefreshCcw, Truck, Wallet } from 'lucide-react';
import Link from 'next/link';

export type FooterType = {
  navigationMenus: NavigationMenuForm[];
};

const content = [
  {
    title: 'Levering door heel Nederland',
    description:
      'Door middel van ons eigen wagenpark kunnen wij leveren in heel Nederland. Dit al vanaf €40,- in de regio.',
    icon: <Truck className="size-12 stroke-1 text-primary" />,
  },
  {
    title: 'Uitgebreid Assortiment',
    description: 'Wij hebben een uitgebreid verhuur assortiment met ruim 900 artikelen',
    icon: <CreditCard className="size-12 stroke-1 text-primary" />,
  },
  {
    title: 'Service gericht',
    description:
      'Het is mogelijk om tot 24 uur van te voren een order bij ons te plaatsen. Afhalen kan zelfs nog op de dag zelf. Wijzingen kunnen tot 2 werkdagen van te voren worden doorgegeven',
    icon: <RefreshCcw className="size-12 stroke-1 text-primary" />,
  },
];

const Footer = ({ footer }: { footer: FooterType }) => {
  return (
    <Section spacing="none" padding="none" gap="none" className="bg-white">
      <Section
        spacing="none"
        padding="none"
        gap="none"
        className="border-border mx-auto grid w-full max-w-7xl gap-px border-y px-4 pt-10 sm:px-6 md:grid-cols-3 md:pt-0 lg:px-8"
      >
        {content.map(({ title, description, icon }) => (
          <div key={title} className="w-full bg-white px-8">
            <div className="mb-10 text-center md:my-[60px]">
              <div className="mx-auto mb-0 flex size-[70px] items-center justify-center rounded-full bg-white shadow-card md:mb-7">
                {icon}
              </div>

              <div>
                <h3 className="mb-3 text-xl font-semibold text-black sm:text-2xl md:text-lg lg:text-xl xl:text-xl">
                  {title}
                </h3>
                <p className="mx-auto max-w-[17rem] text-base font-medium text-description md:text-sm">
                  {description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Section>

      <footer className="bg-gray-100">
        <Section className="flex gap-10 max-xl:flex-wrap md:gap-20">
          <FooterColumn className="mt-0">
            <Logo aria-label="Accuraat Verhuur" className="h-10 w-auto fill-neutral-950" />
            <FooterColumnList>
              <FooterColumnListItem>
                <a href="#" className="flex gap-2">
                  <Wallet className="size-6 text-primary" />
                  <span>
                    Legmeerdijk 169D,
                    <br /> 1432 KA Aalsmeer
                  </span>
                </a>
              </FooterColumnListItem>
              <FooterColumnListItem>
                <a href="#" className="flex gap-2">
                  <PhoneCall className="size-6 text-primary" />
                  <span>020-6410920</span>
                </a>
              </FooterColumnListItem>
              <FooterColumnListItem>
                <a href="#" className="flex gap-2">
                  <Mail className="size-6 text-primary" />
                  <span>info@accuraatverhuur.nl</span>
                </a>
              </FooterColumnListItem>
            </FooterColumnList>
          </FooterColumn>
          <FooterColumns className="w-auto">
            {footer.navigationMenus.map(({ name, links }, i) => (
              <FooterColumn key={i}>
                <FooterHeading>{name}</FooterHeading>
                <FooterColumnList>
                  {links.map((link, i) => (
                    <FooterColumnListItem key={i}>
                      <Link href={link.pathname}>{link.name}</Link>
                    </FooterColumnListItem>
                  ))}
                </FooterColumnList>
              </FooterColumn>
            ))}
          </FooterColumns>
        </Section>
        <Section
          className="border-border grid items-center justify-center space-y-4 border-t py-5"
          padding="none"
          gap="none"
        >
          <FooterCopyright className="mx-auto w-full text-center">
            © 2024 Accuraat Verhuur. Alle rechten voorbehouden.
          </FooterCopyright>
        </Section>
      </footer>
    </Section>
  );
};

export default Footer;
